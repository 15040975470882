import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter,Link} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CircularProgress from '@material-ui/core/CircularProgress';

import TronWeb from 'tronweb';
import { utils as Utils, Ftinu } from '../../utils';
import {tronApi,communityDetailsApi,withdrawApi,packageDetails,missingTransactionApi} from '../../utils';
import ReCAPTCHA from "react-google-recaptcha";
import QRCode from "react-qr-code";
let defaultAmountArr = [];

class Home extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      isConnected: false, 
      tronWeb: {
        installed: false,
        loggedIn: false
      },
      contractInfo: {
        contractAddress:'-',
        contractAddressLinkto: null,
        ReferredByContent:null,
        ReferredBy: null,
      },
      userInfo:{
        YourAddress: '-',
        YourAddressfull:null,
        TotalWithdrawn:0,
        TotalDeposits:0,
        checkpoint:0
      },
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      amountTrx:0,
      globalInfo:{
        user_count: 0,
        withdraw_amount:0,
        investment_amount:0
      },
      userDetails:{
        upline_user: {},
        wallet_balance:0,
        upline_income:0,
        downline_income:0,
        sponsor_income:0,
        user_withdraw:0,
        user_investment:0,
        fasttrack_income:0
      },
      communityDetails:{
        main_user: null,
        upline_list:[],
        downline_list:[],
        captcha:null,
        secret:null
      },
      withdrawRule:{
        reinvestment_percentage:0,
        withdraw_percentage:0
      },
      sponsorDetails:[],
      withdrawlAmount:0,
      captchaValue:null,
      transaction_hash:'',
      support_type:'',
      getValidAddress:null,
      inputValidAddress:null,
      editAddress:true
    }
  }

  async componentDidMount(){
   let data =  await packageDetails();
   defaultAmountArr = data.data;
   console.log("defaultAmountArr",defaultAmountArr)
    await new Promise(resolve => {
    const tronWebState = {
        installed: !!window.tronWeb,
        loggedIn: window.tronWeb && window.tronWeb.ready
    };

    if(tronWebState.installed) {
        this.setState({
            tronWeb:
            tronWebState
        });

        return resolve();
    }

    let tries = 0;

    const timer = setInterval(() => {
        if(tries >= 10) {
            const TRONGRID_API = 'https://api.shasta.trongrid.io';

            window.tronWeb = new TronWeb(
                TRONGRID_API,
                TRONGRID_API,
                TRONGRID_API
            );

            this.setState({
                tronWeb: {
                    installed: false,
                    loggedIn: false
                }
            });

            clearInterval(timer);
            return resolve();
        }

        tronWebState.installed = !!window.tronWeb;
        tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;

        if(!tronWebState.installed)
            return tries++;

        this.setState({
            tronWeb: tronWebState
        });

        resolve();
    }, 10000);
    });

   

    if(!this.state.tronWeb.loggedIn) {
        window.tronWeb.on('addressChanged', () => {
            if(this.state.tronWeb.loggedIn)
                return;

            this.setState({
                tronWeb: {
                    installed: true,
                    loggedIn: true
                }
            });
        });
    }

    setInterval(()=>{
      this.fetchData();
    },3000);    

    this.fetchFtinuData();

    this.communityDetailsApi();

    window.tronWeb.on('addressChanged', () => {
        if(this.state.tronWeb.loggedIn)
        this.fetchData();
        this.communityDetailsApi();
    });
  }

  fetchData= async()=>{
    await Utils.setTronWeb(window.tronWeb);
    
    const contactInfo = await Utils.contract.contractInfo().call();
    const contractBalance = await window.tronWeb.trx.getBalance(Utils.address);
    const contractAddress = Utils.address;

    this.setState(({contractInfo})=>
      ({contractInfo : {...contractInfo, 
      contractAddress:contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length),
      contractAddressLinkto:contractAddress,
      contractBalance: window.tronWeb.fromSun(contractBalance),
      }})
    );

    const users = await Utils.contract.users(window.tronWeb.defaultAddress.base58).call();
    const referrer = window.tronWeb.address.fromHex(users.referrer);
    const checkpoint = users.checkpoint;
    const checkpointVal = window.tronWeb.toDecimal(checkpoint._hex);
    const getUserTotalWithdrawn = await Utils.contract.getUserTotalWithdrawn(window.tronWeb.defaultAddress.base58).call();
    const TotalWithdrawn = window.tronWeb.toDecimal(getUserTotalWithdrawn._hex);
    
    const getUserTotalDeposits = await Utils.contract.getUserTotalDeposits(window.tronWeb.defaultAddress.base58).call();
    const TotalDeposits = window.tronWeb.toDecimal(getUserTotalDeposits._hex);
    
    
    this.setState(({userInfo})=>
      ({userInfo : {...userInfo, 
        YourAddress: window.tronWeb.defaultAddress.base58.slice(0, 10) + '.....' + window.tronWeb.defaultAddress.base58.slice(window.tronWeb.defaultAddress.base58.length-5, window.tronWeb.defaultAddress.base58.length),
        YourAddressfull:window.tronWeb.defaultAddress.base58,
        TotalWithdrawn:window.tronWeb.fromSun(TotalWithdrawn),
        TotalDeposits:window.tronWeb.fromSun(TotalDeposits),
        checkpoint:checkpointVal
      }})
    );

    this.setState(({contractInfo})=>
      ({contractInfo : {...contractInfo, 
        ReferredByContent: referrer.slice(0, 5) + '...' +referrer.slice(referrer.length-5, referrer.length),
        ReferredBy: (referrer != 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') ? referrer : null
      }})
    );
  }

  fetchFtinuData= async()=>{
    await Ftinu.setTronWeb(window.tronWeb);
    let getValidAddress = await Ftinu.contract.getValidAddress(window.tronWeb.defaultAddress.base58).call();
    let editAddress = true;
    if(getValidAddress){
      editAddress = false;
    }
    this.setState({getValidAddress, editAddress});
  }
  
  communityDetailsApi = async()=>{
    if(!window.tronWeb.defaultAddress.base58){
      return false;
    }
    const data = await communityDetailsApi(window.tronWeb.defaultAddress.base58);
    //const data = await communityDetailsApi('TWC8GqmGNkW8m5gYBz1bYKx58qxM8qzdzf');
    //const data = await communityDetailsApi('TEwD1YadPF1ef388xbPwuCWFNGLFDXcJYp');
    //console.log('communityDetailsApi',data);
    if(data.status){
      this.setState(({globalInfo})=>
        ({globalInfo : {...globalInfo, 
          user_count: data.data.user_count,
          withdraw_amount:data.data.withdraw_amount,
          investment_amount:data.data.investment_amount
        }})
      );
      this.setState(({userDetails})=>
        ({userDetails : {...userDetails, 
          upline_user: data.data.upline_user,
          wallet_balance:data.data.wallet_balance,
          upline_income:data.data.upline_income,
          downline_income:data.data.downline_income,
          sponsor_income:data.data.sponsor_income,
          user_withdraw:data.data.user_withdraw,
          user_investment:data.data.user_investment,
          fasttrack_income:data.data.fasttrack_income
        }})
      );
      this.setState(({communityDetails})=>
        ({communityDetails : {...communityDetails, 
          main_user: data.data.main_user,
          upline_list:data.data.upline_list,
          downline_list:data.data.downline_list,
          captcha:data.data.captcha,
          secret:data.data.secret,
        }})
      );
      this.setState(({withdrawRule})=>
        ({withdrawRule : {...withdrawRule, 
          reinvestment_percentage: data.data.withdrawRule.reinvestment_percentage,
          withdraw_percentage:data.data.withdrawRule.withdraw_percentage
        }})
      );
      this.setState({loading:false});
    }else{
      // let defaultError = "Some error occurred!";
      // if(data.hasOwnProperty('error')){
      //   defaultError = data.error;
      // }
      // this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      this.setState({loading:false});
    }
  }

  refreshApiData = async() => {
    this.setState({loading:true},()=>{
      this.communityDetailsApi();
    });
  }

  doSubmit = async(e) => {
    let validateForm = true;
    let ReferredAddress = '';
    if(!window.tronWeb?.defaultAddress?.base58){
      this.props.enqueueSnackbar("Wallet address is not found! Please connect to Tronlink.",{ variant: 'error' });
      this.setState({withdrawlAmountError:true});
      return false;
    }
    if(!this.state.amountTrx){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      validateForm = false;
      return false;
    }

    if(parseFloat(this.state.amountTrx) < 200){
      this.props.enqueueSnackbar("Min deposit 200TRX!",{ variant: 'error' })
      this.setState({amountError:true});
      validateForm = false;
      return false;
    }

    if(this.state.sponsor_address == this.state.userInfo.YourAddressfull){
      

      this.props.enqueueSnackbar("Sponsor Address should not same Deposit Address!",{ variant: 'error' })

      this.setState({sponsor_addressError:true});
      validateForm = false;
      return false;
    }

    if(!this.state.sponsor_address && !this.state.contractInfo.ReferredBy){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        validateForm = false;
        return false;
    }

    if(!validateForm){
        return false;
    }

    if(this.state.contractInfo.ReferredBy){
      ReferredAddress = this.state.contractInfo.ReferredBy
    }

    if(this.state.sponsor_address){
      ReferredAddress = this.state.sponsor_address
    }

    try {
      
      const balance = await window.tronWeb.trx.getBalance(window.tronWeb.defaultAddress.base58);
      // console.log(validateForm, balance);
      if(balance > window.tronWeb.toSun(this.state.amountTrx)){
          this.setState({loading:true});
          const res= await Utils.contract.invest(ReferredAddress).send(
            {
              feeLimit:300000000,
              callValue:window.tronWeb.toSun(this.state.amountTrx)
            }
          );
          // console.log('After Submit...');
          // console.log(res);
          //await  HashPost(res);
          const data = await tronApi(res);
          if(data.status){
            this.setState({loading:false});
            this.props.enqueueSnackbar("Congratulation! Your Deposit made successfully! After 10min it will reflect on dashboard!",{ variant: 'success' })
            this.fetchData();
            this.communityDetailsApi();
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
            //////////// transaction error ////////////
          }

      }else{
        this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' })                
      }

    } catch (err) {
      console.log('------catch-------');
      console.log(err);
      this.setState({loading:false});
      let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';
      if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
        defaultError = window.tronWeb.toUtf8(err.output.contractResult[0]);
      }
      this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
    }
    
  }

  doWithdrawl = async(e) => {
    if(!window.tronWeb?.defaultAddress?.base58){
      this.props.enqueueSnackbar("Wallet address is not found! Please connect to Tronlink.",{ variant: 'error' });
      this.setState({withdrawlAmountError:true});
      return false;
    }
    if(!this.state.withdrawlAmount){
      this.props.enqueueSnackbar("Withdrawal Amount is required!",{ variant: 'error' });
      this.setState({withdrawlAmountError:true});
      return false;
    }

    if(this.state.withdrawlAmount < 5){
      this.props.enqueueSnackbar("Min Withdrawal 5TRX!",{ variant: 'error' })
      this.setState({withdrawlAmountError:true});
      return false;
    }

    if(this.state.withdrawlAmount > 500){
      this.props.enqueueSnackbar("Max Withdrawal 500TRX!",{ variant: 'error' })
      this.setState({withdrawlAmountError:true});
      return false;
    }

    if(this.state.userDetails.wallet_balance < 5){
      this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' });
      return false;
    }

    if(!this.state.captchaValue){
      this.props.enqueueSnackbar("Invalid Captcha!",{ variant: 'error' });
      this.setState({captchaValueError:true});
      return false;
    }

    try {
      this.setState({loading:true});
      const data = await withdrawApi(window.tronWeb.defaultAddress.base58,this.state.withdrawlAmount,this.state.captchaValue,this.state.communityDetails.secret);
      //const data = await withdrawApi('TWB7vLgUPnbr4pyFU4Dk1nSWLSuFQAibYh',this.state.withdrawlAmount,this.state.captchaValue,this.state.communityDetails.secret);
      // console.log('doWithdrawl',data);
      if(data.status){
        this.setState({withdrawlAmount:0,captchaValue:'', loading:false});
        this.props.enqueueSnackbar(data.message,{ variant: 'success' });
        this.fetchData();
        this.communityDetailsApi();
      }else{
        let defaultError = "Transaction failed!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
        this.setState(({communityDetails})=>
        ({communityDetails : {...communityDetails,
          captcha:data.captcha,
          secret:data.secret,
        }})
      );
      }
    } catch (err) {
      console.log('------catch-------');
      console.log(err);
      this.setState({loading:false});
      let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
      this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
    }
  }

  doMissingTrsn = async(e) => {
    if(!this.state.transaction_hash){
      this.props.enqueueSnackbar("Transaction Hash is required!",{ variant: 'error' });
      this.setState({transaction_hashError:true});
      return false;
    }

    try {
      this.setState({loading:true});
      const data = await missingTransactionApi(this.state.transaction_hash);
      //const data = await missingTransactionApi(this.state.transaction_hash);
      //console.log('doMissingTrsn',data);
      if(data.status){
        this.setState({transaction_hash:'', loading:false});
        this.props.enqueueSnackbar(data.message,{ variant: 'success' })
      }else{
        let defaultError = "Transaction failed!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        if(data.hasOwnProperty('message')){
          defaultError = data.message;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    } catch (err) {
      console.log('------catch-------');
      console.log(err);
      this.setState({loading:false});
      let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
      this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
    }
  }

  onChangeCaptcha = (value) => {
    this.setState({captchaValue:value});
    // console.log("Captcha value:", value);
  }

  getTransaction = async(hash)=>{
    await Utils.setTronWeb(window.tronWeb);

    return new Promise( (resolve, reject) => {
      const result = {status:false, data:[]}
      let count = 0;
      let interval = setInterval(async()=>{
        if(count>15){
          reject(false);
          clearInterval(interval);
        }
        let result = await window.tronWeb.trx.getTransaction(hash);
        if(result.hasOwnProperty('ret') && result.ret[0].contractRet == 'SUCCESS'){
          resolve(true);
          clearInterval(interval);
        }
        count++;
      },10000);

    })
    
  }

  updateAddress = async(e) => {
    let validateForm = true;
    let inputValidAddress = this.state.inputValidAddress;

    if(!inputValidAddress){
      this.props.enqueueSnackbar("Address is required!",{ variant: 'error' })

      this.setState({sponsor_addressError:true});
      validateForm = false;
      return false;
    }    

    if(!validateForm){
        return false;
    }

    try {
      await Ftinu.setTronWeb(window.tronWeb);

      const balance = await window.tronWeb.trx.getBalance(window.tronWeb.defaultAddress.base58);
      // console.log(validateForm, balance);
      if(balance >= 8){
          this.setState({loading:true});
          const res= await Ftinu.contract.updateAddress(this.state.inputValidAddress).send(
            {
              feeLimit:300000000,
              callValue:0
            }
          );
          // console.log('After Submit...');
          // console.log(res);
          // if(res){
          //   this.setState({loading:false,editAddress:false});
          //   this.props.enqueueSnackbar("Congratulation! Your Address updated successfully!",{ variant: 'success' })
          // }

          //await  HashPost(res);
          const data = await this.getTransaction(res);
          if(data){
            this.setState({loading:false,editAddress:false});
            this.props.enqueueSnackbar("Congratulation! Your Address updated successfully!",{ variant: 'success' })
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' });
          }

          // const data = await tronApi(res);
          // if(data.status){

          //   this.setState({loading:false,editAddress:false});
          //   this.props.enqueueSnackbar("Congratulation! Your Address updated successfully!",{ variant: 'success' })

          // }else{
          //   this.setState({loading:false});
          //   this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
          //   //////////// transaction error ////////////
          // }

      }else{
        this.props.enqueueSnackbar("Insufficient  Balance.! Minimum 8-10 TRX required to submit.",{ variant: 'error' })                
      }

    } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = window.tronWeb.toUtf8(err.output.contractResult[0]);
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
    }
    
  }

  render() {
    const {classes} = this.props;

    let tableData = [];
    if(this.state.communityDetails.upline_list.length>0){
      this.state.communityDetails.upline_list.map(function(val, index){
        tableData.push(val);
      })        
    }

    if(this.state.communityDetails.main_user){
      tableData.push(this.state.communityDetails.main_user);
    }

    if(this.state.communityDetails.downline_list.length>0){
      this.state.communityDetails.downline_list.map(function(val, index){
        tableData.push(val);
      })
    }

    return (
      <Frame withHeader={true} withFooter={true} isHome={true}>

        {this.state.loading ? (
          <div className="loader-container">
            <div className="linespinner"></div>
          </div>
        ):(null)}

        <section className="banner_section">
          <div className="container">
            <div className="banner_text text-center">
              <h1>Tronfactory -  A New Generation of <span>Investment</span> Platform!</h1>
              <p>Exclusive investment platform based on Tron smart contract protocols.</p>
              <img src="img/heading_line.png" alt="" />
            </div>
          </div>
        </section>

        <section className="pb-4">
          <div className="container">
            <div className="banner_bottom_bg">
              <ul className="Community_ul">
                <li>
                  <h3>{parseFloat(this.state.userDetails.user_investment).toFixed(2)} TRX</h3>
                  <h4>Total Deposit</h4>
                </li>
                <li>
                  <h3>{parseFloat(this.state.userDetails.user_withdraw).toFixed(2)} TRX</h3>
                  <h4>Total Withdrawn</h4>
                </li>
                <li>
                  <h3>{parseFloat(this.state.userDetails.sponsor_income).toFixed(2)} TRX</h3>
                  <h4>Incentive Income</h4>
                </li>
                <li>
                  <h3>{this.state.contractInfo.ReferredBy ? this.state.contractInfo.ReferredByContent : '-------'}</h3>
                  <h4>Referred By</h4>
                </li>
              </ul>
              <img className="header_left_icon move3" src="img/tron_icon.png" alt="" />
              <img className="header_right_icon move3" src="img/tron_icon.png" alt="" />
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5">
          <div className="container">
            <div className="main_heading middle_heading mb-5">
              <h2>Simple Steps to Invest- 1,2,3!</h2>
              <img src="img/heading_line.png" alt="" />
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="kyc_box">
                  <img src="img/icon1.png" alt="" />
                  <h4>1.Create Account</h4>
                  <p>The first thing you need to do is setup a Tronfactory account.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="kyc_box">
                  <img src="img/icon2.png" alt="" />
                  <h4>2. Fund Account</h4>
                  <p>Store Tron in your personal crypto wallet and hold it in your account.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="kyc_box">
                  <img src="img/icon3.png" alt="" />
                  <h4>3. Execute Trade</h4>
                  <p>Once you place your trade, it will show up in your dedicated account.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5">
          <div className="container">
            <div className="main_heading middle_heading mb-5">
              <h2>Join Tronfactory Platform</h2>
              <img src="img/heading_line.png" alt="" />
            </div>
            <div className="middle_container">
              <ul className="trx_btn">
                {defaultAmountArr?.map((index,key) => {return(
                  <li key={key}>
                    <button className={this.state.amountTrx==index.minimum_price?'active':'inactive'} 
                      onClick={e => this.setState({amountTrx:index.minimum_price})}>
                      {index.minimum_price+' TRX'}
                    </button>
                  </li>
                  )                              
                })}
              </ul>
              <div className="mb-4">
                {!this.state.contractInfo.ReferredBy ? (                  
                  <input className="form-control" type="text" placeholder="Sponsor Address"
                    name="sponsor_address"
                    onChange={e => this.setState({sponsor_address:e.target.value})}
                    value={this.state.sponsor_address}
                    error={this.state.sponsor_addressError}
                  />
                ):(
                  <input className="form-control" type="text" placeholder="Sponsor Address" 
                    readOnly
                    value={this.state.contractInfo.ReferredBy}
                  />
                )}
                
              </div>
              <div className="text-left">
                <button disabled={this.state.loading} onClick={e => this.doSubmit(e)} className="theme_btn">Join Now</button>
              </div>
            </div>
          </div>
        </section>


        <section className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="wallet_box bg1">
                      <h5>Total Community</h5>
                      <p>{this.state.globalInfo.user_count}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="wallet_box bg2">
                      <h5>Total TRX Invested</h5>
                      <p>{parseFloat(this.state.globalInfo.investment_amount).toFixed(2)}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="wallet_box bg3">
                      <h5>Total Withdrawal</h5>
                      <p>{parseFloat(this.state.globalInfo.withdraw_amount).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="wallet_box bg4">
                      <h5>Upline Income</h5>
                      <p>{parseFloat(this.state.userDetails.upline_income).toFixed(2)} TRX</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="wallet_box bg5">
                      <h5>Downline Income</h5>
                      <p>{parseFloat(this.state.userDetails.downline_income).toFixed(2)} TRX</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="wallet_box bg6">
                      <h5>Wallet Balance</h5>
                      <p>{parseFloat(this.state.userDetails.wallet_balance).toFixed(2)} TRX</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="wallet_box bg7">
                      <h5>Fasttrack Income</h5>
                      <p>{parseFloat(this.state.userDetails.fasttrack_income).toFixed(2)} TRX</p>
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-lg-6">
                    <div className="wallet_box bg8">
                      <h5>Affiliate Level</h5>
                      <p>0.00 TRX</p>
                    </div>
                  </div>*/}
                </div> 
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="join_withdraw_box">
                  
                  <div className="sm_heading text-center">
                    <h4>Withdraw Tronfactory Platform</h4>
                  </div>
                  <div className="mb-2">
                    <label>Available TRX</label>
                    <input className="form-control" type="text" readOnly value={this.state.userDetails.wallet_balance} />
                  </div>
                  <div className="mb-2">
                    <label>Withdrawal TRX(Min:100 TRX)</label>
                    <input className="form-control" type="number" 
                      onChange={e => this.setState({withdrawlAmount:e.target.value})}
                      value={this.state.withdrawlAmount}
                      error={this.state.withdrawlAmountError}
                    />
                  </div>
                  <div className="mb-2">
                    <label>Reinvestment Amount (TRX)</label>
                    <input className="form-control" type="text" readOnly value={this.state.withdrawRule.reinvestment_percentage*this.state.withdrawlAmount/100} />
                  </div>
                  <div className="mb-2">
                    <label>Withdrawable Amount (TRX)</label>
                    <input className="form-control" type="text" readOnly value={this.state.withdrawRule.withdraw_percentage*this.state.withdrawlAmount/100} />
                  </div>
                  {this.state.communityDetails?.captcha?(
                    <div className="mb-2">
                      <img className="mb-2" src={this.state.communityDetails?.captcha} />
                      <br />
                      <label>Enter Captcha</label>
                      <input className="form-control" type="text" onChange={(e)=>this.onChangeCaptcha(e.target.value)} value={this.state.captchaValue} />
                    </div>
                  ):(null)}
                  <div className="text-center">
                    <button className="theme_btn" disabled={this.state.loading} onClick={e => this.doWithdrawl(e)}>Withdraw</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="copy_link_bg">
          <div className="container">
            <div className="main_heading middle_heading mb-5">
              <h2>Your Referral Link</h2>
              <img src="img/heading_line.png" alt="" />
            </div>
            <div className="text-center">
              <h2 className="mb-3">https://tronfactory.io/{this.state.userInfo.YourAddress}</h2>
              <CopyToClipboard text={`https://tronfactory.io/${this.state.userInfo.YourAddressfull}`}
                onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                <button title="copy Link" className="theme_btn">Copy Link</button>
              </CopyToClipboard>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5 position-relative">
          <div className="container mb-5">
            <div className="main_heading middle_heading mb-5">
              <h2>Community Levels</h2>
              <img src="img/heading_line.png" alt="" />
            </div>
            <div className="banner_bottom_bg teble-box">
              <div className="teble-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th style={{width:'20%'}}>Level</th>
                      <th style={{width:'50%'}}>User ID</th>
                      <th>Investment</th>
                    </tr>
                  </thead>
                  <tbody className='cus_scroll'>
                    {this.state.communityDetails.main_user ? (
                      tableData.length>0 ? (
                        tableData.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`up${index}`} className={class_name}>
                              <td style={{width:'20%'}}>
                                {val.get_bonus ? (<i class="fa fa-circle" style={{color:'green',paddingRight:10}}></i> ) : 
                                (<i class="fa fa-circle" style={{color:val.level == 0?'orange':'red',paddingRight:10}}></i> )
                                }
                                Level-{val.level}
                              </td>
                              <td style={{width:'50%'}}>{val.username}</td>
                              <td>{val.total_investment} TRX</td>
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <img className="fishing_man" src="img/fishing.png" alt="" />
                        <p className="mb-0">No Data Available!</p>
                      </td>
                    </tr>
                    )} 
                     
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        
          <img className="body_shape2" src="img/shape-1.png" alt="" />
        </section>

      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  },
  buttonProgress: {
    //color: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
//export default Home;
